import React, { FC, useEffect, useState } from 'react';
import { Link, navigate, PageProps } from 'gatsby';

import 'bootstrap/dist/css/bootstrap.css';
import '../styles/Index.scss';
import classes from './careers.module.scss';

import Navbar from '../components/Navbar/Navbar';
import Hero, { HeroType } from '../components/Hero/Hero';
import { CareersPageCaption, SeoCaption, WP_URL } from '../constants';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import { Row, Col, Accordion, Spinner } from 'react-bootstrap';
import AccordionItem from '../components/Accordion/AccordionItem';
import Button from '../components/Button/Button';
import { Html5Entities } from 'html-entities';

export interface CareerListItem {
  content: {
    rendered: string;
  };
  slug: string;
  title: {
    rendered: string;
  };
}

const Careers: FC<PageProps> = ({ path }) => {
  const [careers, setCareers] = useState<CareerListItem[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${WP_URL}/wp-json/wp/v2/posts?per_page=100`)
      .then((response) => response.json())
      .then((posts: CareerListItem[]) => {
        setCareers(posts);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        navigate('/404');
      });
  }, []);

  return (
    <Layout>
      <SEO title={SeoCaption.CAREERS} />
      <Hero page={HeroType.CAREERS}>
        <Navbar pathname={path} />
        <div className={classes.TitleContainer}>
          <div className={classes.Title}>{CareersPageCaption.HEADER_TITLE}</div>
        </div>
      </Hero>
      <Row className={classes.FirstSection}>
        <Col sm={12} md={5} className={classes.FirstLeftColumn}>
          {CareersPageCaption.FIRST_SECTION_LEFT_COLUMN}
        </Col>
        <Col sm={12} md={7} className={classes.FirstRightColumn}>
          <p>{CareersPageCaption.FIRST_SECTION_RIGHT_COLUMN_P1}</p>
          <p>{CareersPageCaption.FIRST_SECTION_RIGHT_COLUMN_P2}</p>
        </Col>
      </Row>
      <div className={classes.SecondSection}>
        <Hero page={HeroType.GREY_BACKGROUND} />
        <Row className={classes.GreyContainer}>
          <Col sm={12} md={5} className={classes.LeftGreyColumn}>
            <p>{CareersPageCaption.SECOND_SECTION_TITLE}</p>
            <p className={classes.LeftGreyColumnDescription}>
              {CareersPageCaption.SECOND_SECTION_DESCRIPTION}
            </p>
			<p className={classes.LeftGreyColumnDescription}>
              <a href={CareersPageCaption.JOB_LINK}>{CareersPageCaption.JOB_TITLE}</a>
            </p>
          </Col>
          <Col sm={12} md={7} className={classes.RightGreyColumn}>
            {!loading ? (
              <Accordion defaultActiveKey="0">
                {careers?.map((item: CareerListItem, i: number) => (
                  <div key={i} className={classes.AccordionItemWrapper}>
                    <AccordionItem
                      eventKey={i}
                      title={Html5Entities.decode(item.title.rendered)}
                    >
                      <div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.content.rendered,
                          }}
                        />
                        <div className={classes.CareerButton}>
                          <Link to={`/careers/${item.slug}`}>
                            <Button>{CareersPageCaption.APPLY}</Button>
                          </Link>
                        </div>
                      </div>
                    </AccordionItem>
                  </div>
                ))}
              </Accordion>
            ) : (
              <div className={classes.SpinnerWrapper}>
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  className={classes.Spinner}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Careers;
