import React, { FC, useContext, ReactElement } from 'react';
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from 'react-bootstrap';
import classes from './AccordionItem.module.scss';
import AccordionPlus from '../../images/accordion-plus.svg';
import AccordionMinus from '../../images/accordion-minus.svg';
import classNames from 'classnames';

interface AccordionItemProps {
  eventKey: number;
  title: string;
  children: ReactElement;
}

interface Toggle {
  eventKey: string;
  title: string;
}

const ContextAwareToggle = ({ eventKey, title }: Toggle) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;

  const decoratedOnClick = useAccordionToggle(eventKey);

  return (
    <Card.Header
      className={classNames({
        [classes.CardHeader]: true,
        [classes.CardHeaderCollapsed]: !isCurrentEventKey,
        [classes.CardHeaderExpanded]: isCurrentEventKey,
      })}
      onClick={decoratedOnClick}
    >
      <div className={classes.Title}>{title}</div>
      {!isCurrentEventKey ? (
        <img src={AccordionPlus} alt="expand" />
      ) : (
        <img src={AccordionMinus} alt="collapse" />
      )}
    </Card.Header>
  );
};

const AccordionItem: FC<AccordionItemProps> = ({
  eventKey,
  title,
  children,
}) => {
  return (
    <Card className={classes.Card}>
      <ContextAwareToggle eventKey={eventKey.toString()} title={title} />
      <Accordion.Collapse eventKey={eventKey.toString()}>
        <Card.Body className={classes.CardBody}>{children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default AccordionItem;
